/* .background {
    width: 500px;
    height: 300px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
} */

.background .shape {
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
}

.shape:first-child {
    background: linear-gradient(#1845ad,
            #23a2f6);
    left: -80px;
    top: -80px;
}

.shape:last-child {
    background: linear-gradient(to right,
            #ff512f,
            #f09819);
    right: -30px;
    bottom: -80px;
}

.loginForm {
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;;
    align-items: center;
}